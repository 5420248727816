var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "panel"
  }, [_c('div', {
    staticClass: "panel-content"
  }, [_c('el-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.moreFilterVisible,
      expression: "moreFilterVisible"
    }],
    staticClass: "more-filter",
    attrs: {
      "label-position": "left",
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "报备渠道"
    }
  }, [_c('el-autocomplete', {
    attrs: {
      "fetch-suggestions": function fetchSuggestions(k, c) {
        return _vm.autocompleteFetch(k, c, 2);
      },
      "clearable": "",
      "placeholder": "全称、简称"
    },
    on: {
      "select": function select(e) {
        return _vm.search(e, 'organs');
      },
      "clear": _vm.search
    },
    model: {
      value: _vm.queryData.organ,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "organ", $$v);
      },
      expression: "queryData.organ"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "报备人员"
    }
  }, [_c('el-autocomplete', {
    attrs: {
      "fetch-suggestions": function fetchSuggestions(k, c) {
        return _vm.autocompleteFetch(k, c, 3);
      },
      "clearable": "",
      "placeholder": "姓名、电话"
    },
    on: {
      "select": function select(e) {
        return _vm.search(e, 'users');
      },
      "clear": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "autocomplete-item-label"
        }, [_vm._v(_vm._s(item.value))]), _c('div', {
          staticClass: "autocomplete-item-value",
          attrs: {
            "title": item.sub
          }
        }, [_vm._v(" " + _vm._s(item.sub) + " ")])];
      }
    }]),
    model: {
      value: _vm.queryData.user,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "user", $$v);
      },
      expression: "queryData.user"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "到访次数"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "选择",
      "multiple": "",
      "collapse-tags": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.visitNumList,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "visitNumList", $$v);
      },
      expression: "queryData.visitNumList"
    }
  }, _vm._l(_vm.conditionMap.visitCounts, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "客户判定"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "选择",
      "multiple": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.determineStarList,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "determineStarList", $$v);
      },
      expression: "queryData.determineStarList"
    }
  }, _vm._l(_vm.conditionMap.customRates, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "置业顾问"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "选择",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.consultantId,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "consultantId", $$v);
      },
      expression: "queryData.consultantId"
    }
  }, _vm._l(_vm.conditionMap.consultants, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "报备状态"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "选择",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.reportState,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "reportState", $$v);
      },
      expression: "queryData.reportState"
    }
  }, _vm._l(_vm.conditionMap.statuses, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "带看奖励"
    }
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.visitPrize,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "visitPrize", $$v);
      },
      expression: "queryData.visitPrize"
    }
  }, _vm._l(_vm.conditionMap.visitAwardStatuses, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "filter-bar"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "w300 f-search-bar",
    attrs: {
      "placeholder": "单号、姓名、电话"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('div', {
    staticClass: "group"
  }, [_c('el-select', {
    model: {
      value: _vm.dateType,
      callback: function callback($$v) {
        _vm.dateType = $$v;
      },
      expression: "dateType"
    }
  }, [_c('el-option', {
    attrs: {
      "value": 1,
      "label": "报备时间"
    }
  }), _c('el-option', {
    attrs: {
      "value": 2,
      "label": "到访时间"
    }
  })], 1), _c('el-date-picker', {
    attrs: {
      "type": "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": "yyyy-MM-dd",
      "unlink-panels": "",
      "picker-options": _vm.pickerOptions
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "报备项目",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.estate,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "estate", $$v);
      },
      expression: "queryData.estate"
    }
  }, _vm._l(_vm.conditionMap.estates, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1)], 1), _c('el-button-group', {
    staticClass: "actions-btn"
  }, [_c('f-table-fields', {
    attrs: {
      "name": "reportVisit".concat(_vm.tab),
      "options": _vm.defaultFields
    },
    on: {
      "change": _vm.onFieldsChange
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "iconfont icon-list"
    }
  })], 1), _c('el-button', {
    attrs: {
      "icon": "iconfont icon-refresh"
    },
    on: {
      "click": _vm.getReportList
    }
  }), _c('el-button', {
    attrs: {
      "icon": "iconfont icon-clean"
    },
    on: {
      "click": _vm.clearQueryData
    }
  }), _c('el-button', {
    attrs: {
      "icon": "iconfont icon-filter"
    },
    on: {
      "click": function click($event) {
        _vm.moreFilterVisible = !_vm.moreFilterVisible;
      }
    }
  })], 1)], 1), _c('f-table', {
    ref: "table",
    staticClass: "table",
    attrs: {
      "row-key": "rowKey",
      "lazy": "",
      "border": "",
      "data": _vm.list,
      "load": _vm.loadHistoryReportList,
      "tree-props": {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      "columns": _vm.tableColumns,
      "row-style": _vm.rowStyle,
      "summary-method": _vm.getSummaries,
      "show-summary": ""
    },
    on: {
      "sort-change": _vm.onTableSort
    }
  })], 1), _c('f-pagination', {
    attrs: {
      "shadow": "",
      "name": "reportList".concat(_vm.tab),
      "fixed": false,
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getReportList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }